import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Auth, API } from 'aws-amplify';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../buttons';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  setPendingUsers: (any) => void;
  pendingUsers: Array<any>;
}

const AddUserToAccountModal = ({
  pendingUsers,
  setPendingUsers,
}: Props): JSX.Element => {
  const [accountType, setAccountType] = React.useState('MEMBER');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentAccount } = useSelector((state: any) => state.account);
  const { user } = useSelector((state: any) => state.user);
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, handleSubmit } = useForm();
  const validAddition = (): boolean => {
    return true;
  };

  const addUser = async ({ email }: { email: string }) => {
    try {
      if (currentAccount?.pricePlan.roleBasedManagement) {
        toast.error('Your current plan does not include role based management');
        return;
      }
      setIsLoading(true);
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        body: {
          email,
          firstName: user?.given_name,
          lastName: user?.family_name,
          userRole: accountType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.post(
        'openscreen',
        `accounts/${currentAccount?.accountId}/invitations`,
        myInit
      );
      const newList = [...pendingUsers];
      newList.push(res.accountInvitation);
      setPendingUsers(newList);
      toast.success('Your invitation was sent.');
      onClose();
      setIsLoading(false);
    } catch (error) {
      toast.error('Your invitation could not be sent.', error.message);
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <PrimaryButton isLoading={isLoading} onClick={onOpen}>
        Add User
      </PrimaryButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        {validAddition() ? (
          <ModalContent>
            <form action="submit" onSubmit={handleSubmit(addUser)}>
              <ModalHeader>Invite User to Account</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column">
                  {/* Give options */}
                  <RadioGroup
                    marginBottom="15px"
                    onChange={setAccountType}
                    value={accountType}
                  >
                    <Stack direction="column">
                      <Radio value="ADMINISTRATOR">
                        Admin Access (Full access to this account)
                      </Radio>
                      <Radio value="MEMBER">
                        Developer (Can view and manage API keys and projects)
                      </Radio>
                      <Radio value="BILLING_CONTACT">
                        Billing (Can View, manage or pay for your subscription)
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Input {...register('email')} placeholder="Email" />
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <PrimaryButton isLoading={isLoading} type="submit">
                  Invite
                </PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>
              As a part of your plan, you have reached the max amount of users
              per account
            </ModalHeader>
            <ModalCloseButton />

            <ModalFooter>
              <PrimaryButton onClick={onClose}>Close</PrimaryButton>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
      <ToastContainer theme="colored" />
    </>
  );
};

export default AddUserToAccountModal;
