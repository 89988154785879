import * as React from 'react';
import {
  Text,
  Skeleton,
  Heading,
  Box,
  Flex,
  Stack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { Auth, API } from 'aws-amplify';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import AddUserToAccountModal from '../../modals/addUserToAccount/addUserToAccount';

const ManageUsers = (): JSX.Element => {
  const [users, setUsers] = React.useState([]);
  const [pending, setPendingUsers] = React.useState([]);

  const { currentAccount } = useSelector((state: any) => state.account);

  const getUsers = async () => {
    if (currentAccount?.accountId) {
      try {
        const token = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        const res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(res.users);
        setPendingUsers(res.invitations);
      } catch (error) {
        toast.error('Could not get users.', error.message);
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    // Axios get account users
    getUsers();
  }, [currentAccount?.accountId]);

  return (
    <Flex
      padding="20px"
      bgColor="#fff"
      direction="column"
      marginX="40px"
      maxW="70vw"
    >
      <Heading marginLeft="2rem" marginBottom="20px">
        Manage Users
      </Heading>
      <Box borderY="1px" marginLeft="2rem" borderColor="gray.200">
        <Flex
          w="100%"
          marginLeft="20px"
          marginY="20px"
          justify="space-between"
          align="flex-end"
        >
          <Text>
            Below are the users associated with this Openscreen account
          </Text>
          <AddUserToAccountModal
            pendingUsers={pending}
            setPendingUsers={setPendingUsers}
          />
        </Flex>
        <Flex paddingX="20px">
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>User</Th>
                <Th>Role</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((e) => {
                return (
                  <Tr>
                    <Td>{e.email}</Td>
                    <Td>{e.userRole}</Td>
                    <Td>Active</Td>
                  </Tr>
                );
              })}
              {pending.map((e) => {
                return (
                  <Tr>
                    <Td>{e.email}</Td>
                    <Td>{e.userRole}</Td>
                    <Td>Pending</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        {!users.length ? (
          <Stack maxH="80vh">
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
          </Stack>
        ) : (
          <></>
        )}
      </Box>
      <ToastContainer theme="colored" />
    </Flex>
  );
};

export default ManageUsers;
